import "./base.css";
import { createApp } from "vue";
import { createPinia } from "pinia";
import { createI18n } from "vue-i18n";
import mitt from "mitt";
import "./registerServiceWorker";
import App from "./App.vue";
import router from "./router";

const emitter = mitt();

import fr from "./locales/fr.json";
import en from "./locales/en.json";
const i18n = createI18n({
  locale: "fr",
  messages: {
    fr,
    en,
  },
});

const pinia = createPinia();
const app = createApp(App);
app.config.globalProperties.emitter = emitter;

app.use(i18n);
app.use(pinia);
app.use(router);

app.mount("#app");
