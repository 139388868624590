import { defineStore } from "pinia";
import gameScriptFr from "../script/fr_script.json";
import gameScriptEn from "../script/en_script.json";
export const useGameStore = defineStore("user", {
  state: () => ({
    currentDialog: null,
    currentScene: null,
    characters: null,
    backgrounds: null,
    quiz: null,
    qcms: null,
    sceneId: 0,
    dialogId: 0,
    textSpeed: 50,
    fontSize: 1.5,
    language: "fr",
    quizPropositions: [],
    qcmAnswers: [],
  }),
  actions: {
    initOptions() {
      if (localStorage.getItem("textSpeed")) {
        let option = localStorage.getItem("textSpeed");
        switch (option) {
          case "option1":
            this.textSpeed = 100;
            break;
          case "option2":
            this.textSpeed = 50;
            break;
          case "option3":
            this.textSpeed = 20;
            break;
          default:
            this.textSpeed = 50;
            break;
        }
      }
      if (localStorage.getItem("fontSize")) {
        this.fontSize = localStorage.getItem("fontSize");
      }
      if (localStorage.getItem("language")) {
        this.language = localStorage.getItem("language");
      }
      if (localStorage.getItem("sceneId")) {
        this.sceneId = parseInt(localStorage.getItem("sceneId"));
      }
      if (localStorage.getItem("dialogId")) {
        this.dialogId = parseInt(localStorage.getItem("dialogId"));
      }
      if (localStorage.getItem("qcmAnswers")) {
        this.qcmAnswers = JSON.parse(localStorage.getItem("qcmAnswers"));
      }
    },
    selectlanguage() {
      switch (this.language) {
        case "fr":
          return gameScriptFr;
        case "en":
          return gameScriptEn;
        default:
          return gameScriptFr;
      }
    },
    initPage() {
      // this find the characters in script and load it
      this.characters = this.selectlanguage().characters;
      // this find the backgrounds in script and load it
      this.backgrounds = this.selectlanguage().backgrounds;

      this.quiz = this.selectlanguage().quiz;
      this.qcms = this.selectlanguage().qcms;
      // this find the sceneId in script and load it
      this.currentScene = this.selectlanguage().scenes.find(
        (scene) => this.sceneId === scene.sceneId
      );
      // this find the dialogId in sceneId and load it
      this.currentDialog = this.currentScene.dialogs.find(
        (dialog) => this.dialogId === dialog.dialogId
      );
    },
    getName(dialog) {
      if (!dialog || dialog.characterId === null) {
        return null;
      }
      // this return the character name from a dialog object
      if (this.currentScene && this.currentScene.characters && dialog) {
        return this.characters.find(
          (character) => dialog.characterId === character.id
        ).name;
      } else {
        return "Name not found";
      }
    },
    getScene() {
      // this return the current scene from the store
      if (this.currentScene) {
        return this.currentScene;
      } else {
        return null;
      }
    },
    getDialog() {
      // this return the current dialog object list from the store
      if (this.currentDialog) {
        return this.currentDialog;
      } else {
        return null;
      }
    },
    getCharacters() {
      // this return the characters list from the store
      if (this.getScene() && this.getScene().characters) {
        return this.characters.filter((character) =>
          this.getScene().characters.includes(character.id)
        );
      } else {
        return [];
      }
    },
    getText(dialog) {
      // this return the text from a dialog object
      if (dialog) {
        return dialog.text;
      } else {
        return "";
      }
    },
    getQuiz(dialog) {
      // this return the quiz from a dialog object
      if (dialog && (dialog.quizId || dialog.quizId === 0)) {
        return this.quiz.find((quiz) => quiz.quizId === dialog.quizId );
      } else {
        return null;
      }
    },
    getQuizTitle(quiz) {
      // this return the title of a quiz in a dialog object
      if (quiz && quiz.title) {
        return quiz.title;
      } else {
        return "";
      }
    },
    getQuizQuestion(quiz) {
      // this return the question of a quiz in a dialog object
      if (quiz && quiz.question) {
        return quiz.question;
      } else {
        return "";
      }
    },
    getQuizPropositions(quiz) {
      // this return the question of a quiz in a dialog object
      if (quiz && quiz.propositions) {
        return quiz.propositions;
      } else {
        return [];
      }
    },
    getButtons() {
      // this return the buttons from a dialog object
      if (this.getDialog()) {
        return this.getDialog().buttons;
      } else {
        return [];
      }
    },
    isMainCharacter(dialog) {
      // this check if character is main
      if (dialog && this.getCharacters()[0].id === dialog.characterId) {
        return true;
      } else {
        return false;
      }
    },
    isQuiz(dialog) {
      // this check if dialog have to be display as quiz
      if (dialog && (dialog.quizId || dialog.quizId === 0)) {
        return true;
      } else {
        return false;
      }
    },
    loadDialog(sceneLink, dialogLink) {
      // load dialog data from sceneLink and dialogLink in selected langage
      this.sceneId = sceneLink;
      this.dialogId = dialogLink;
      this.currentScene = this.selectlanguage().scenes.find(
        (scene) => this.sceneId === scene.sceneId
      );
      this.currentDialog = this.currentScene.dialogs.find(
        (dialog) => this.dialogId === dialog.dialogId
      );
      localStorage.setItem("sceneId", this.sceneId);
      localStorage.setItem("dialogId", this.dialogId);
      this.clearQuizPropositions()
    },
    addQuizProposition(propositionId, text, isCorrect) {
      // add an proposition to the store from script dialog
      this.quizPropositions.push({
        propositionId,
        text,
        checked: false,
        isCorrect,
      });
    },
    clearQuizPropositions() {
      // remove all propositions from array
      this.quizPropositions = [];
    },
    toggleCheckQuizProposition(propositionId) {
      // check or uncheck an proposition
      let proposition = this.quizPropositions.find(
        (proposition) => proposition.propositionId === propositionId
      );
      if (!proposition.checked) {
        this.uncheckOtherQuizPropositions();
      }
      proposition.checked = !proposition.checked;
    },
    uncheckOtherQuizPropositions() {
      // uncheck all propositions
      for (let i = 0; i < this.quizPropositions.length; i++) {
        const proposition = this.quizPropositions[i];
        proposition.checked = false;
      }
    },

    isQcm(dialog) {
      // this check if dialog have to be display as qcm
      if (dialog && (dialog.qcmId || dialog.qcmId === 0)) {
        return true;
      } else {
        return false;
      }
    },
    isQcmResult(dialog) {
      // this check if dialog have to be display as qcm
      if (dialog && dialog.checkResultQCM) {
        return true;
      } else {
        return false;
      }
    },
    getQcmResult(dialog) {
      // this check if dialog have to be display as qcm
      if (dialog && dialog.checkResultQCM) {
        return dialog.checkResultQCM;
      } else {
        return false;
      }
    },
    getQcm(dialog) {
      // this return the qcm from a dialog object
      if (dialog && (dialog.qcmId || dialog.qcmId === 0)) {
        return this.qcms.find((qcm) => qcm.qcmId === dialog.qcmId );
      } else {
        return null;
      }
    },
    getQcmById(id) {
      // this return the qcm from a dialog object
      if (this.qcms && this.qcms.length) {
        return this.qcms.find((qcm) => qcm.qcmId === id);
      } else {
        return null;
      }
    },
    getQcmTitle(qcm) {
      // this return the title of a qcm in a dialog object
      if (qcm && qcm.title) {
        return qcm.title;
      } else {
        return "";
      }
    },
    getQcmQuestion(qcm) {
      // this return the question of a qcm in a dialog object
      if (qcm && qcm.question) {
        return qcm.question;
      } else {
        return "";
      }
    },
    getQcmAnswers(qcm) {
      // this return the answers of a qcm in a dialog object
      if (qcm && qcm.answers) {
        return qcm.answers;
      } else {
        return [];
      }
    },
    addQcmObject() {
      let currentQcm = this.getQcm(this.getDialog());
      if (currentQcm) {
        if (!this.qcmAnswers && !this.qcmAnswers.length) {
          return;
        }
        let qcmExist = this.qcmAnswers.find(
          (qcm) => qcm.qcmId === currentQcm.qcmId
        );
        if (qcmExist) {
          return;
        }
        let qcmObject = { qcmId: currentQcm.qcmId, answers: [] };
        this.qcmAnswers.push(qcmObject);
        localStorage.setItem("qcmAnswers", JSON.stringify(this.qcmAnswers));
      }
    },
    addQcmAnswer(answerId, text) {
      // add an answer to the store from script dialog
      this.qcmAnswers.push({ answerId, text, checked: false });
    },
    clearQcmAnswers() {
      // remove all answers from array
      this.qcmAnswers = [];
    },
    toggleCheckQcmAnswer(answerId) {
      // check or uncheck an answer
      let currentQcm = this.getQcm(this.getDialog());

      if (currentQcm) {
        // Trouver le QCM correspondant dans le store
        let qcmToModify = this.qcmAnswers.find(
          (qcm) => qcm.qcmId === currentQcm.qcmId
        );

        // Si le QCM n'existe pas encore dans les réponses, l'initialiser
        if (!qcmToModify) {
          qcmToModify = { qcmId: currentQcm.qcmId, answers: [] };
          this.qcmAnswers.push(qcmToModify);
        }

        // Rechercher la réponse actuelle
        let answer = qcmToModify.answers.find(
          (answer) => answer.answerId === answerId
        );

        if (answer) {
          // Si la réponse existe déjà, inverser son statut checked
          answer.checked = !answer.checked;

          // Si elle est décochée, la supprimer du tableau
          if (!answer.checked) {
            qcmToModify.answers = qcmToModify.answers.filter(
              (a) => a.answerId !== answerId
            );
          }
        } else {
          // Si la réponse n'existe pas, la récupérer et la marquer comme cochée
          let currentQcmAnswer = this.getQcmAnswers(currentQcm).find(
            (a) => a.answerId === answerId
          );

          if (currentQcmAnswer) {
            currentQcmAnswer.checked = true;
            qcmToModify.answers.push(currentQcmAnswer);
          }
        }
        localStorage.setItem("qcmAnswers", JSON.stringify(this.qcmAnswers));
      }
    },
  },
});
