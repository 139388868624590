<script>
import { useGameStore } from "@/store/gameStore.js";
export default {
  setup() {
    const gameStore = useGameStore();
    return { gameStore };
  },
  data() {
    return {
      checked: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.menuClose();
      }
    },
  },
  computed: {
    checkGameStarted() {
      if (!this.gameStore.sceneId && !this.gameStore.dialogId) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    menuOpen(e) {
      if (e.target.checked) {
        this.checked = true;
      } else {
        this.checked = false;
      }
    },
    menuClose() {
      this.checked = false;
      document.getElementById("active").checked = false;
    },
    restartGame() {
      this.gameStore.loadDialog(0, 0);
      localStorage.removeItem('qcmAnswers');
      this.gameStore.qcmAnswers = [];
      this.$router.push("/game");
    },
  },
};
</script>

<template>
  <div>
    <div class="main-menu-mobile">
      <div class="menu">
        <input
          type="checkbox"
          id="active"
          ref="menuInput"
          @change="menuOpen($event)"
        />
        <label for="active" class="menu-btn">
          <div class="box">
            <div class="btn" :class="checked ? 'active' : 'not-active'">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </label>
        <div class="wrapper" @click="menuClose">
          <div class="wrapper-content">
            <router-link
              class="no-deco link-box"
              :class="$route.name === 'home' ? 'active-link' : ''"
              to="/"
            >
              <div class="img icon-home"></div>
              <p class="link-mobile">{{ $t("main-menu.home") }}</p></router-link
            >
            <router-link
              class="no-deco link-box"
              :class="$route.name === 'game' ? 'active-link' : ''"
              to="/game"
            >
              <div class="img icon-play"></div>
              <p class="link-mobile">{{ $t("main-menu.game") }}</p></router-link
            >
            <div
              v-if="checkGameStarted"
              class="no-deco link-box"
              @click.prevent="restartGame"
            >
              <div class="img icon-restart"></div>
              <p class="link-mobile">{{ $t("main-menu.restart") }}</p>
            </div>
            <router-link
              class="no-deco link-box"
              :class="$route.name === 'settings' ? 'active-link' : ''"
              to="/settings"
            >
              <div class="img icon-settings"></div>
              <p class="link-mobile">
                {{ $t("main-menu.settings") }}
              </p></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.language-changer {
  color: var(--white);
  text-decoration: underline;
  position: absolute;
  bottom: 10px;
}
.main-menu-mobile {
  display: none;
}
.menu {
  width: 55px;
}
.wrapper {
  z-index: 10;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 100%;
  height: 100%;
  width: 350px;
  background: var(--main);
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.wrapper-content {
  width: calc(100% - 40px);
  height: 100%;
  padding: 100px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.menu-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  right: 0px;
  top: 0px;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  font-size: 20px;
  cursor: pointer;
}
.menu-btn .img {
  height: 25px;
  width: 25px;
}
input[type="checkbox"] {
  display: none;
}

#active:checked ~ .wrapper {
  left: calc(100% - 350px);
}
.no-deco {
  text-decoration: none;
}
.box {
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  cursor: pointer;
}

span {
  display: block;
  width: 100%;
  border-radius: 3px;
  height: 2px;
  background: var(--black);
  transition: all 0.3s;
  position: relative;
}

span + span {
  margin-top: 7px;
}

.active span:nth-child(1) {
  animation: ease 0.7s top forwards;
  filter: invert(1);
  box-shadow: 0 0 2px 0 white;
}

.not-active span:nth-child(1) {
  animation: ease 0.7s top-2 forwards;
  filter: invert(1);
  box-shadow: 0 0 2px 0 white;
}

.active span:nth-child(2) {
  animation: ease 0.7s scaled forwards;
  filter: invert(1);
  box-shadow: 0 0 2px 0 white;
}

.not-active span:nth-child(2) {
  animation: ease 0.7s scaled-2 forwards;
  filter: invert(1);
  box-shadow: 0 0 2px 0 white;
}

.active span:nth-child(3) {
  animation: ease 0.7s bottom forwards;
  filter: invert(1);
  box-shadow: 0 0 2px 0 white;
}

.not-active span:nth-child(3) {
  animation: ease 0.7s bottom-2 forwards;
  filter: invert(1);
  box-shadow: 0 0 2px 0 white;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 9px;
    transform: rotate(0);
  }
  100% {
    top: 9px;
    transform: rotate(45deg);
    filter: invert(1);
    box-shadow: 0 0 0 0 white;
  }
}

@keyframes top-2 {
  0% {
    top: 9px;
    transform: rotate(45deg);
  }
  50% {
    top: 9px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 9px;
    transform: rotate(0);
  }
  100% {
    bottom: 9px;
    transform: rotate(135deg);
    filter: invert(1);
    box-shadow: 0 0 0 0 white;
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 9px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 9px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.link-mobile p,
.link-box p {
  padding: 5px 10px 0;
}
.link-mobile {
  color: var(--white);
  font-size: 1.5rem;
  padding: 5px 10px;
  transition: all 0.1s ease-in-out;
  font-family: "Utopia", sans-serif;
}
.link-box:hover .link-mobile {
  color: var(--main);
}
.link-box {
  display: flex;
  width: calc(100% - 20px);
  padding: 5px 10px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid var(--white);
  transition: all 0.1s ease-in-out;
}
.link-box:hover {
  background-color: var(--white);
}
.link-box .img {
  width: 30px;
  height: 30px;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: var(--white);
  display: inline;
  transition: all 0.2s ease-in-out;
}
.icon-settings {
  -webkit-mask-image: url(@/assets/icons/settings.svg);
  mask-image: url(@/assets/icons/settings.svg);
}
.icon-play {
  -webkit-mask-image: url(@/assets/icons/play.svg);
  mask-image: url(@/assets/icons/play.svg);
}
.icon-home {
  -webkit-mask-image: url(@/assets/icons/home.svg);
  mask-image: url(@/assets/icons/home.svg);
}
.icon-restart {
  -webkit-mask-image: url(@/assets/icons/restart.svg);
  mask-image: url(@/assets/icons/restart.svg);
}
.link-box:hover .img {
  background-color: var(--main);
}
.active-link {
  background-color: var(--white);
}

.active-link .link-mobile {
  color: var(--main);
}
.active-link .img {
  background-color: var(--main);
}
.main-menu-logo-invert {
  filter: invert(1);
}
.main-menu-mobile {
  display: block;
  height: 60px;
}

.bottomLinks .app-navlink {
  margin: 0 10px;
  padding: 0;
}
@media screen and (max-width:1024px) {
  #active:checked ~ .wrapper {
    left: 0;
  }

  .wrapper {
    width: 100%;
  }
}
</style>
