<script>
import { useGameStore } from "@/store/gameStore.js";
import MainMenuItem from './components/general/MainMenuItem.vue';
export default {
  setup() {
    const gameStore = useGameStore();
    return { gameStore };
  },
  components:{
    MainMenuItem
  },
  mounted() {
    this.gameStore.initOptions();
    this.$i18n.locale = this.gameStore.language;
  } 
}
</script>

<template>
<MainMenuItem></MainMenuItem>
<router-view v-slot="{ Component }">
  <transition name="fade">
    <component :is="Component" />
  </transition>
</router-view>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
